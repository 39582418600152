<!--
 * @Author: zhai
 * @Description: 工作经验、工作亮点富文本
-->
<template>
  <div ref="editor" class="tinymce-editor">
    <editor
      :id="ids"
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onClick="onClick"
    >
    </editor>
  </div>
</template>
   
  <script>
import tinymce from "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver/theme";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/colorpicker";
import "tinymce/plugins/textcolor";
import "tinymce/themes/silver/theme.min.js";
import "tinymce/skins/ui/oxide/skin.min.css";
export default {
  components: {
    Editor,
  },
  props: {
    value: {
      //传入一个value，使组件支持v-model绑定
      type: String,
      default: "",
    },
    placeholderHtml: {
      // placeholder文案
      type: String,
      default: "请输入",
    },
    ids: {
      // 需要渲染的id
      type: String,
      default: "",
    },
    height: {
      type: Number,
      default: 300,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default: "",
    },
    toolbar: {
      type: [String, Array],
      default: "",
      // default: "bold italic underline",
    },
  },
  data() {
    return {
      myValue: this.value,
      //初始化配置
      init: {
        selector: `#${this.ids}`,
        // language_url: "../../static/tinymce/langs/zh-Hans.js", // 根据自己文件的位置，填写正确的路径。路径不对会报错
        // language: "zh_CN",
        skin_url: "../../static/tinymce/skins/ui/oxide/skin.css", // 根据自己文件的位置，填写正确的路径。路径不对会报错
        height: this.height,
        plugins: this.plugins,
        toolbar: this.toolbar,
        statusbar: false,
        menubar: false,
        branding: false,
        forced_root_block: false,
        placeholder: "请输入",
        lineheight_formats: 1.0,
        content_style: "body { color: #606266; }",
        // 其他配置项
        // that: this,
        // setup: function (editor) {
        //   // 富文本placeholder值，文案与工作
        //   let html1 = `<div style="color: #c0c4cc; white-space: pre-wrap;">示例: 在职期间负责的核心工作职责,建议不要超过3点。<br />如果就职过多个职位，请分段描述;<br /><br />例子:<br /><br />模式1、在司担任某某岗位，任职期间，主要负责xxxxx工作；在此期间，组建了xxxx，实现了xxxx；<br />模式2、在担任xxx岗位期间，主要负责xxx工作，具体的工作职责包括如下；<br />2.1）xxxxxxx；<br />2.2）xxxxxxx；<br />2.3）xxxxxxx；</div>`;
        //   let html2 = `<div style="color: #c0c4cc; white-space: pre-wrap;">示例: 打破常规，超越同行或其他同事的优秀业绩；或有开创性且突破公司期望值的杰出贡献，且给公司带来价值和收益。<br /><br />请举例2-3个工作亮点：<br /><br />a. 是什么：某项目背景或工作目标或难点<br />b. 为什么：为什么会出现这个项目工作／难点，旨在体现分析过程，为您后面提出解决方案做铺垫<br />c. 怎么处理：您是怎么把这件事情做好的？用了什么技术/方法？有何突出/创新之处？<br />d. 有什么价值：为公司、客户或您本身带来了哪些价值？客户或您本身带来了哪些价值？<br />最好用实际数据证明，比如销售业绩增加了多少，或者利润增加了多少，客户群体增加了多少等。</div>`;
        //   let ts = this;
        //   editor.on("init", function () {
        //     // 在编辑器初始化时检查内容，并添加或删除placeholder
        //     var content = editor.getContent();
        //     if (!content.trim()) {
        //       // 如果内容为空
        //       editor.setContent(
        //         '<div style="color: #c0c4cc; white-space: pre-wrap;">' +
        //           ts.that.placeholderHtml +
        //           "</div>"
        //       );
        //     }
        //   });

        //   editor.on("keyup", function () {
        //     // 监听键盘输入事件，检查内容，并添加或删除placeholder
        //     var content = editor.getContent();
        //     if (!content.trim()) {
        //       // 如果内容为空
        //       // editor.setContent(
        //       //   '<div style="color: #c0c4cc; white-space: pre-wrap;">' +
        //       //     ts.that.placeholderHtml +
        //       //     "</div>"
        //       // );
        //     } else if (content.trim() === html1 || content.trim() === html2) {
        //       // 如果内容为placeholder
        //       editor.setContent(""); // 移除placeholder
        //     }
        //   });
        //   editor.on("focus", function () {
        //     // 获取焦点
        //     var content = editor.getContent();
        //     if (content.trim() === html1 || content.trim() === html2) {
        //       editor.setContent("");
        //     }
        //   });
        //   editor.on("blur", function () {
        //     // 失去焦点且内容为空时，重新添加placeholder
        //     if (editor.getContent() === "") {
        //       editor.execCommand(
        //         "mceSetContent",
        //         false,
        //         '<div style="color: #c0c4cc; white-space: pre-wrap;">' +
        //           ts.that.placeholderHtml +
        //           "</div>"
        //       );
        //     }
        //     document.getElementById(ts.that.ids + "_ifr").blur();
        //   });
        // },
      },
    };
  },
  mounted() {
    tinymce.init({});
    // this.initTinymcePlaceholder();
  },
  methods: {
    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    onClick(e) {
      this.$emit("onClick", e, tinymce);
    },
    initTinymcePlaceholder() {
      let that = this;
      tinymce.PluginManager.add("placeholder", function (editor) {
        console.log("editor", editor);
      });
    },
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
<style scoped>
</style>
  